import axios from 'axios'; 
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const BlogPostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const API_KEY = process.env.REACT_APP_API_KEY;

  const decryptData = (encryptedData) => {
    try {
      const [ivHex, encryptedText] = encryptedData.split(':');
      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedText);
      const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY.padEnd(32, ' '));

      const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      setApiError('Failed to decrypt data.');
      return null;
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://clashroyale.qwest.crdeckbuilder.com/api/blog/${id}`, {
          headers: { 'x-api-key': API_KEY },
        });

        const decryptedData = decryptData(response.data.data);
        if (decryptedData) {
          setPost(decryptedData);
        }
      } catch (error) {
        setApiError('Failed to fetch post data.');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id, API_KEY]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('https://clashroyale.qwest.crdeckbuilder.com/api/blog', {
          headers: { 'x-api-key': API_KEY },
        });

        if (response.status !== 200) throw new Error(`Failed to fetch blog posts: ${response.statusText}`);

        const { data: encryptedData } = response.data;
        if (!encryptedData) throw new Error('No encrypted data received');

        const decryptedData = decryptData(encryptedData);
        if (decryptedData) {
          const sortedPosts = decryptedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setRelatedPosts(sortedPosts.slice(0, 6)); 
        } else {
          throw new Error('Error decrypting blog post data');
        }
      } catch (error) {
        console.error('API Error:', error.message);
        setApiError(`Failed to fetch blog posts: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  const handleReadMore = (post) => {
    navigate(`/blog/${post.id}`);
  };

  const extractYouTubeID = (url) => {
    const regExp = /^.*(?:youtu\.be\/|v\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };

  if (loading) return <p>Loading...</p>;
  if (apiError) return <p className="text-red-500">{apiError}</p>;

  return (
    <HelmetProvider>
      <div className="min-h-screen w-full flex flex-col md:flex-row p-4 bg-gray-100 relative">
        {post && (
          <Helmet>
            <title>{post.title} | Blog</title>
            <meta name="description" content={post.excerpt || post.content.slice(0, 150)} />
            <meta name="keywords" content={`blog, ${post.title}, related posts`} />
            <meta name="author" content="Blog Author" />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.excerpt || post.content.slice(0, 150)} />
            <meta property="og:image" content={post.imageUrl} />
          </Helmet>
        )}

        <div className="w-full md:w-5/6 bg-white p-4 md:p-6 shadow-lg rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl md:text-2xl font-bold">{post?.title || 'Post Title'}</h1>

            <button
              className="md:hidden bg-blue-600 text-white px-4 py-2 rounded-full"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              {isSidebarOpen ? 'Close' : 'Related Posts'}
            </button>
          </div>

          {post?.imageUrl && (
            <img src={post.imageUrl} alt={post.title} className="w-full h-auto rounded-md mb-4" />
          )}

          <div
            dangerouslySetInnerHTML={{ __html: post?.content || 'Loading content...' }}
            className="text-gray-700 mb-4 text-sm md:text-base"
          ></div>

          {post?.youtubeUrl && (
            <iframe
              src={`https://www.youtube.com/embed/${extractYouTubeID(post.youtubeUrl)}`}
              className="w-full h-48 md:h-64 rounded-md"
              frameBorder="0"
              allowFullScreen
              title={post?.title}
            ></iframe>
          )}

          {post?.externalLinks && post.externalLinks.length > 0 && (
            <div className="external-links mt-6">
              <h4 className="text-lg font-semibold mb-2">Related Links:</h4>
              <ul className="list-disc list-inside">
                {post.externalLinks.map((link) => (
                  <li key={link.url} className="mb-2">
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {relatedPosts.map((relatedPost) => (
            <div
              key={relatedPost.id}
              className="mb-4 p-2 border-b cursor-pointer hover:bg-gray-200"
              onClick={() => handleReadMore(relatedPost)}
            >
              <h3 className="text-sm md:text-lg font-bold">{relatedPost.title}</h3>
            </div>
          ))}
        </div>

        <aside
          className={`${
            isSidebarOpen ? 'block' : 'hidden'
          } md:block md:w-1/6 bg-gray-50 p-6 fixed top-0 right-0 h-full md:relative md:h-auto z-20 shadow-lg`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Related Posts</h2>
            <button
              className="md:hidden bg-red-600 text-white px-2 py-1 rounded-full"
              onClick={() => setIsSidebarOpen(false)}
            >
              X
            </button>
          </div>

          {relatedPosts.map((relatedPost) => (
            <div
              key={relatedPost.id}
              className="mb-4 p-2 border-b cursor-pointer hover:bg-gray-200"
              onClick={() => handleReadMore(relatedPost)}
            >
              <h3 className="text-sm md:text-lg font-bold">{relatedPost.title}</h3>
            </div>
          ))}
        </aside>
      </div>
    </HelmetProvider>
  );
};

export default BlogPostDetail;
