import React, { useEffect, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import axios from 'axios';
import CryptoJS from 'crypto-js';
import './Deckshop.css';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

// Lazy load Cards component
const Cards = React.lazy(() => import('./Cards'));

export default function BestDeck() {
  const [decks, setDecks] = useState([]);
  const [adsVisible, setAdsVisible] = useState(true);
  const [showPopupAd, setShowPopupAd] = useState(false);
  const [bannerAdHtml, setBannerAdHtml] = useState('');
  const [popupAdHtml, setPopupAdHtml] = useState('');
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchDecks();


    const timer = setTimeout(() => {
      setShowPopupAd(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const decryptData = (encryptedData) => {
    try {
      const [ivHex, encryptedText] = encryptedData.split(':');
      if (!ivHex || !encryptedText) throw new Error('Invalid encrypted data format');

      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedText);
      const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY.padEnd(32, ' '));

      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        key,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );

      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) throw new Error('Failed to decrypt the data');
      return JSON.parse(decryptedText);
    } catch (error) {
      console.error('Decryption Error:', error.message);
      setApiError('Failed to decrypt data.');
      return null;
    }
  };

  const fetchDecks = async () => {
    setLoading(true);
    setApiError(null);
    try {
      const response = await fetch('https://clashroyale.qwest.crdeckbuilder.com/api/deck-shop', {
        method: 'GET',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching decks: ${response.statusText}`);
      }

      const responseData = await response.json();
      const { data: encryptedData } = responseData;

      if (!encryptedData) {
        throw new Error('No encrypted data received');
      }

      const decryptedData = decryptData(encryptedData);

      if (decryptedData) {
        setDecks(decryptedData);
      } else {
        throw new Error('Error decrypting deck data');
      }
    } catch (error) {
      setApiError(`Failed to fetch decks: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };



  const filterDecks = () => {
    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return decks.filter(deck => 
        deck.category === 'Best Deck' && 
        (deck.deckName.toLowerCase().includes(lowerCaseSearchQuery) ||
        deck.deck.some(card => card.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
        deck.stats.averageElixirCost.toString().includes(lowerCaseSearchQuery))
      );
    }
    return decks.filter(deck => deck.category === 'Best Deck');
  };

  const closePopupAd = () => setShowPopupAd(false);
  const copyDeckToClashRoyale = (deckLink) => window.open(deckLink, '_blank');

  const renderDecksByCategory = () => {
    const categoryDecks = filterDecks();
    const indexOfLastDeck = currentPage * itemsPerPage;
    const indexOfFirstDeck = indexOfLastDeck - itemsPerPage;
    const currentDecks = categoryDecks.slice(indexOfFirstDeck, indexOfLastDeck);

    if (currentDecks.length === 0) return null;

    return (
      <div className="mb-8 p-6 rounded-lg glass-container">
        <h2 className="text-3xl font-bold mb-4 text-white">Best Deck</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentDecks.map((deckItem, index) => (
            <Suspense key={index} fallback={<div>Loading deck...</div>}>
              <Cards deckItem={deckItem} />
            </Suspense>
          ))}
        </div>
        {categoryDecks.length > itemsPerPage && (
          <div className="flex justify-center mt-4 space-x-2">
            {[...Array(Math.ceil(categoryDecks.length / itemsPerPage)).keys()].map(pageNumber => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber + 1)}
                className={`px-3 py-1 rounded ${currentPage === pageNumber + 1 ? 'bg-purple-500 text-white ' : 'bg-gray-200 text-gray-700'}`}
              >
                {pageNumber + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
     <Helmet>
  <title>Top Clash Royale Decks | Best Decks for Every Arena | Deckshop</title>
  <meta name="description" content="Explore top Clash Royale decks with high win rates and optimized elixir costs. Copy the best decks and dominate every arena with Deckshop!" />
  <meta name="keywords" content="
clash royale best deck,
clash royale best deck,
clash royale best deck 2024,
clash royale best decks for each arena,
clash royale best deck arena 6,
clash royale best deck for arena 11,
clash royale best deck arena 4,
clash royale best deck arena 3,
clash royale best deck for arena 7,
clash royale best deck arena 9,
clash royale best deck arena 5,Clash Royale, Best Decks, Deckshop, high win rate decks, optimized elixir cost, Clash Royale strategies, Clash Royale deck guide, top decks for Clash Royale, best Clash Royale decks by arena" />
  <link rel="canonical" href="https://crdeckbuilder.com/best-decks" />
</Helmet>


      <div className="mb-8 p-6 rounded-lg text-white">
        <h1 className="text-4xl font-bold mb-4">Best Decks</h1>
        <p className="text-xl text-center">Find the perfect Best Deck for your next battle!</p>
      </div>

      {apiError && <div className="text-red-500 mb-4">{apiError}</div>}

      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-8 relative">
            <div className="absolute left-3 top-3 text-black">
              <MagnifyingGlassIcon className="h-5 w-5" />
            </div>
            <input
              type="text"
              placeholder="Search decks or cards"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {renderDecksByCategory()}

          {adsVisible && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4"></h2>
              <div dangerouslySetInnerHTML={{ __html: bannerAdHtml }} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
