// src/components/AdminDeckCreator.js

import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import api from '../api/axios';
import { AuthContext } from '../AuthContext';
import './AdminDeckCreator.css';
import './Blog.css'; // Assuming you're using CSS for styling

const AdminDeckCreator = () => {
  const { user, logout } = useContext(AuthContext);

  // Deck creation state
  const [deckLink, setDeckLink] = useState('');
  const [deckName, setDeckName] = useState('');
  const [category, setCategory] = useState('');
  const [averageElixirCost, setAverageElixirCost] = useState('');
  const [winRate, setWinRate] = useState('');

  // Ads state
  const [bannerAd, setBannerAd] = useState('');
  const [popupAd, setPopupAd] = useState('');
  const [adsVisible, setAdsVisible] = useState(true); // Control Ads visibility

  // Blog post state
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState(''); // Rich text content (using Quill)
  const [imageFile, setImageFile] = useState(null); // Image file
  const [imageUrl, setImageUrl] = useState(''); // Image URL
  const [externalImageUrl, setExternalImageUrl] = useState(''); // External Image URL
  const [imageSize, setImageSize] = useState('medium'); // Image size (small, medium, large)
  const [externalLinks, setExternalLinks] = useState([{ url: '', text: '' }]); // External links
  const [youtubeUrl, setYoutubeUrl] = useState(''); // YouTube video link
  const [message, setMessage] = useState('');
  const [posts, setPosts] = useState([]); // Blog posts
  const [editMode, setEditMode] = useState(false); // Blog post edit mode
  const [editPostId, setEditPostId] = useState(null); // ID of the post being edited
  const [selectedTemplate, setSelectedTemplate] = useState('template1'); // Selected template

  // Quill toolbar options
  const toolbarOptions = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],        // toggled buttons
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image'],                      // add links and images
    [{ 'size': ['small', 'medium', 'large'] }], // custom dropdown
    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults
    ['clean']                               // remove formatting
  ];

  // Fetch blog posts and ads visibility on component mount
  useEffect(() => {
    fetchBlogPosts();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch blog posts
  const fetchBlogPosts = async () => {
    try {
      const response = await api.get('/api/s3cr3-admin/blog');
      console.log('API Response:', response.data); // For debugging

      // Determine the correct path to the posts array
      let fetchedPosts = [];

      if (Array.isArray(response.data.data)) {
        fetchedPosts = response.data.data;
      } else if (Array.isArray(response.data.posts)) {
        fetchedPosts = response.data.posts;
      } else if (Array.isArray(response.data)) {
        fetchedPosts = response.data;
      } else {
        console.warn('Unexpected API response structure:', response.data);
      }

      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error fetching blog posts:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error fetching blog posts');
    }
  };



  // Handle Deck Creation
  const handleCreateDeck = async () => {
    if (!deckLink || !deckName || !category || !averageElixirCost || !winRate) {
      setMessage('All fields are required');
      return;
    }

    try {
      const response = await api.post('/api/s3cr3-admin/create_deck', {
        deckLink,
        deckName,
        category,
        averageElixirCost: parseFloat(averageElixirCost),
        winRate: parseFloat(winRate),
        bannerAd,
        popupAd,
      });

      setMessage(response.data.message);
      resetDeckForm();
    } catch (error) {
      console.error('Error creating deck:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error creating deck');
    }
  };

  // Reset deck form fields
  const resetDeckForm = () => {
    setDeckLink('');
    setDeckName('');
    setCategory('');
    setAverageElixirCost('');
    setWinRate('');
    setBannerAd('');
    setPopupAd('');
  };

  // Handle Ads Visibility Toggle
  const toggleAdsVisibility = async () => {
    try {
      const response = await api.post('/api/s3cr3-admin/toggle_ads', {
        adsVisible: !adsVisible
      });
      setAdsVisible(response.data.adsVisible);
    } catch (error) {
      console.error('Error toggling ads visibility:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error toggling ads visibility');
    }
  };

  // Handle Ad submission
  const handleSubmitAds = async () => {
    try {
      const response = await api.post('/api/s3cr3-admin/set_ads', {
        bannerAdHtml: bannerAd,
        popupAdHtml: popupAd
      });

      setMessage(response.data.message);
    } catch (error) {
      console.error('Error updating ads:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error updating ads');
    }
  };

  // Handle image file selection
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  // Handle image upload
  const handleUploadImage = async () => {
    if (!imageFile) {
      setMessage('Please select an image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const response = await api.post('/api/s3cr3-admin/upload_image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setImageUrl(response.data.imageUrl);
      setMessage('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error uploading image');
    }
  };

  // Handle external links change
  const handleExternalLinkChange = (index, field, value) => {
    const updatedLinks = [...externalLinks];
    updatedLinks[index][field] = value;
    setExternalLinks(updatedLinks);
  };

  // Add new external link
  const addExternalLink = () => {
    setExternalLinks([...externalLinks, { url: '', text: '' }]);
  };

  // Remove external link
  const removeExternalLink = (index) => {
    const updatedLinks = externalLinks.filter((_, i) => i !== index);
    setExternalLinks(updatedLinks);
  };

  // Handle blog post creation or update
  const handleCreateOrUpdateBlogPost = async () => {
    if (!blogTitle || !blogContent) {
      setMessage('Blog title and content are required');
      return;
    }

    const postData = {
      title: blogTitle,
      content: blogContent,
      imageUrl: imageFile ? imageUrl : externalImageUrl, // Use uploaded image or external URL
      imageSize,
      externalLinks,
      youtubeUrl,
      template: selectedTemplate, // Save selected template
    };

    try {
      if (editMode) {
        // Update existing post
        await api.put(`/api/s3cr3-admin/update_blog/${editPostId}`, postData);
        setMessage('Blog post updated successfully');
      } else {
        // Create new post
        await api.post('/api/s3cr3-admin/create_blog', postData);
        setMessage('Blog post created successfully');
      }

      resetForm();
      fetchBlogPosts();
    } catch (error) {
      console.error('Error creating/updating blog post:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error creating/updating blog post');
    }
  };

  // Reset form fields
  const resetForm = () => {
    setBlogTitle('');
    setBlogContent('');
    setImageFile(null);
    setImageUrl('');
    setExternalImageUrl('');
    setImageSize('medium');
    setExternalLinks([{ url: '', text: '' }]);
    setYoutubeUrl('');
    setEditMode(false);
    setEditPostId(null);
    setSelectedTemplate('template1');
  };

  // Set form fields for editing a post
  const handleEditBlogPost = (post) => {
    setBlogTitle(post.title);
    setBlogContent(post.content);
    setImageUrl(post.imageUrl);
    setExternalImageUrl(''); // Reset the external image URL on edit
    setImageSize(post.imageSize);
    setExternalLinks(post.externalLinks || [{ url: '', text: '' }]);
    setYoutubeUrl(post.youtubeUrl);
    setEditMode(true);
    setEditPostId(post.id); // Use the post's ID for editing
    setSelectedTemplate(post.template || 'template1'); // Set selected template
  };

  // Handle blog post deletion
  const handleDeleteBlogPost = async (postId) => {
    try {
      await api.delete(`/api/s3cr3-admin/delete_blog/${postId}`);
      setMessage('Blog post deleted successfully');
      fetchBlogPosts(); // Refresh posts after deletion
    } catch (error) {
      console.error('Error deleting blog post:', error.response?.data);
      setMessage(error.response?.data?.error || 'Error deleting blog post');
    }
  };

  return (
    <div className="admin-deck-creator-container">
      <h1>Admin Deck Creator</h1>

      {/* Logout Button */}
      <button onClick={logout} className="logout-button">Logout</button>

      {/* Deck Creation Form */}
      <h2>Create Deck</h2>
      <input
        type="text"
        placeholder="Deck Link"
        value={deckLink}
        onChange={(e) => setDeckLink(e.target.value)}
      />
      <input
        type="text"
        placeholder="Deck Name"
        value={deckName}
        onChange={(e) => setDeckName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <input
        type="number"
        placeholder="Average Elixir Cost"
        value={averageElixirCost}
        onChange={(e) => setAverageElixirCost(e.target.value)}
      />
      <input
        type="number"
        placeholder="Win Rate"
        value={winRate}
        onChange={(e) => setWinRate(e.target.value)}
      />

      {/* Ad Inputs */}
      <textarea
        placeholder="Banner Ad HTML"
        value={bannerAd}
        onChange={(e) => setBannerAd(e.target.value)}
      />
      <textarea
        placeholder="Popup Ad HTML"
        value={popupAd}
        onChange={(e) => setPopupAd(e.target.value)}
      />
      <button onClick={handleCreateDeck}>Create Deck</button>
      <button onClick={toggleAdsVisibility}>
        {adsVisible ? 'Hide Ads' : 'Show Ads'}
      </button>
      <button onClick={handleSubmitAds}>Submit Ads</button>

      {/* Blog Post Creation Form */}
      <h2>{editMode ? 'Edit Blog Post' : 'Create Blog Post'}</h2>
      <input
        type="text"
        placeholder="Blog Title"
        value={blogTitle}
        onChange={(e) => setBlogTitle(e.target.value)}
      />
      <ReactQuill
        value={blogContent}
        onChange={setBlogContent}
        modules={{ toolbar: toolbarOptions }}
      />

      {/* Image Upload */}
      <input type="file" accept="image/*" onChange={handleImageChange} />
      <button onClick={handleUploadImage}>Upload Image</button>
      <input
        type="text"
        placeholder="External Image URL"
        value={externalImageUrl}
        onChange={(e) => setExternalImageUrl(e.target.value)}
      />

      {/* External Links */}
      <h3>External Links</h3>
      {externalLinks.map((link, index) => (
        <div key={index} className="external-link">
          <input
            type="text"
            placeholder="URL"
            value={link.url}
            onChange={(e) => handleExternalLinkChange(index, 'url', e.target.value)}
          />
          <input
            type="text"
            placeholder="Link Text"
            value={link.text}
            onChange={(e) => handleExternalLinkChange(index, 'text', e.target.value)}
          />
          <button onClick={() => removeExternalLink(index)}>Remove Link</button>
        </div>
      ))}
      <button onClick={addExternalLink}>Add External Link</button>

      {/* YouTube Video Link */}
      <input
        type="text"
        placeholder="YouTube Video URL"
        value={youtubeUrl}
        onChange={(e) => setYoutubeUrl(e.target.value)}
      />

      {/* Image Size Selection */}
      <label>Image Size:</label>
      <select value={imageSize} onChange={(e) => setImageSize(e.target.value)}>
        <option value="small">Small</option>
        <option value="medium">Medium</option>
        <option value="large">Large</option>
      </select>

      {/* Template Selection */}
      <label>Choose a Template:</label>
      <select value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
        <option value="template1">Template 1</option>
        <option value="template2">Template 2</option>
        <option value="template3">Template 3</option>
      </select>

      <button onClick={handleCreateOrUpdateBlogPost}>
        {editMode ? 'Update Blog Post' : 'Create Blog Post'}
      </button>

      {/* Blog Post List */}
      <h2>Blog Posts</h2>
      <ul>
        {Array.isArray(posts) && posts.length > 0 ? (
          posts.map((post) => (
            <li key={post.id}>
              <h3>{post.title}</h3>
              <button onClick={() => handleEditBlogPost(post)}>Edit</button>
              <button onClick={() => handleDeleteBlogPost(post.id)}>Delete</button>
            </li>
          ))
        ) : (
          <li>No blog posts available.</li>
        )}
      </ul>

      {message && <p>{message}</p>}
    </div>
  );
};

export default AdminDeckCreator;
