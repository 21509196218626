// src/components/YouTube.js
import React from 'react';
import { Helmet } from 'react-helmet';
import YouTube from 'react-youtube';

const YouTubePage = () => {
  const videoIds = [
    '7W1vmg9ezdg',
    'RE8J9-1CNJE', // Replace with actual YouTube video IDs
    '_DVQzNUMW6o',
    'sksry9fIMRI',
    // Add more video IDs as needed
  ];

  const opts = {
    height: '360',
    width: '100%', // Set to 100% for responsive width
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <>
      <Helmet>
        <title>YouTube Channel - Clash Royale Deck Builder</title>
        <meta name="description" content="Watch our latest videos on Clash Royale strategies, deck building tips, and game updates on our YouTube channel." />
        <meta name="keywords" content="YouTube Channel, Clash Royale Deck Builder, Video Tutorials, Deck Building Tips, Clash Royale Strategies" />
        <meta property="og:title" content="YouTube Channel - Clash Royale Deck Builder" />
        <meta property="og:description" content="Stay updated with the latest Clash Royale strategies and tips on our YouTube channel." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.yourwebsite.com/youtube" />
        <meta property="og:image" content="https://www.yourwebsite.com/path-to-thumbnail.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="YouTube Channel - Clash Royale Deck Builder" />
        <meta name="twitter:description" content="Watch our Clash Royale video tutorials and deck building tips." />
        <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-thumbnail.jpg" />
        <link rel="canonical" href="https://www.yourwebsite.com/youtube" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-r from-purple-800 to-indigo-900 p-6">
        <div className="glassmorphism p-8 rounded-lg shadow-lg max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold text-white mb-6 text-center">Our YouTube Channel</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {videoIds.map((id, index) => (
              <div key={index} className="w-full aspect-w-16 aspect-h-9"> {/* Aspect ratio for responsiveness */}
                <YouTube videoId={id} opts={opts} className="w-full h-full" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default YouTubePage;
