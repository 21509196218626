// src/components/FAQs.js
import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

const FAQs = () => {
  const faqData = [
    {
      question: 'Is Clash Royale safe for my child?',
      answer: (
        <span>
          Clash Royale is rated for ages 13+ and includes in-game purchases. To ensure your child’s safety, you can manage in-game spending through parental controls. It is advisable to monitor playtime and encourage responsible gaming habits.
        </span>
      ),
    },
    {
      question: 'Does Clash Royale cost money?',
      answer: (
        <span>
          Clash Royale is free to download and play, but it offers in-app purchases such as gems, chests, and battle passes that enhance gameplay. Players can still enjoy the game without spending real money.
        </span>
      ),
    },
    {
      question: 'Which is better: Clash of Clans or Clash Royale?',
      answer: (
        <span>
          Both games are developed by Supercell, but they offer different experiences. Clash of Clans focuses on base-building and resource management, while Clash Royale is a real-time strategy game with quick-paced card battles. It depends on your gameplay preference!
        </span>
      ),
    },
    {
      question: 'How long does it take to beat Clash Royale?',
      answer: (
        <span>
          Clash Royale doesn’t have a definitive “end.” The game is designed for ongoing progression with new cards, arenas, and challenges. Reaching the highest arenas and maxing out your cards can take months or even years, depending on playtime.
        </span>
      ),
    },
    {
      question: 'How do I build a strong deck in Clash Royale?',
      answer: (
        <span>
          To build a strong deck, ensure you have a balance of offensive and defensive cards, a manageable elixir cost, and cards that synergize well. You can try different combinations using our {' '}
          <a href="https://crdeckbuilder.com/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Builder
          </a>.
        </span>
      ),
    },
    {
      question: 'Can I save my decks in Clash Royale?',
      answer: (
        <span>
          Yes, Clash Royale allows you to save multiple decks for different strategies and game modes. For expert suggestions, check out our {' '}
          <a href="https://crdeckbuilder.com/deck-shop" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Shop
          </a>.
        </span>
      ),
    },
    {
      question: 'What does a Clash Royale deck need to succeed?',
      answer: (
        <span>
          A successful Clash Royale deck typically includes a balance of cheap and expensive cards, a good win condition (such as Hog Rider or Royal Giant), spells (like Fireball or Log), and versatile troops for defense and offense. You can create your perfect deck with our {' '}
          <a href="https://crdeckbuilder.com/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Builder
          </a>.
        </span>
      ),
    },
    {
      question: 'What is the best beginner Clash Royale deck?',
      answer: (
        <span>
          For beginners, a simple and effective deck includes cards like Giant, Musketeer, Mini P.E.K.K.A, and Fireball. These cards are easy to use and provide good versatility. You can explore more beginner-friendly decks in our {' '}
          <a href="https://crdeckbuilder.com/deck-shop" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Shop
          </a>.
        </span>
      ),
    },
    {
      question: 'How many Clash Royale decks are possible?',
      answer: (
        <span>
          With over 100 cards available, the number of possible deck combinations is enormous. You can explore different strategies, from control to beatdown, using various card combinations. For inspiration, visit our {' '}
          <a href="https://crdeckbuilder.com/deck-shop" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Shop
          </a>.
        </span>
      ),
    },
    {
      question: 'How do I get the best deck in Clash Royale?',
      answer: (
        <span>
          The best deck depends on your playstyle and the current game meta. Experimenting with different cards and learning from top players can help you find the right deck. You can also use our {' '}
          <a href="https://crdeckbuilder.com/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Builder
          </a> {' '}
          to customize a deck that suits you.
        </span>
      ),
    },
    {
      question: 'How much RAM does Clash Royale need?',
      answer: (
        <span>
          Clash Royale runs smoothly on devices with at least 1GB of RAM, but 2GB or more is recommended for optimal performance, especially during intense battles.
        </span>
      ),
    },
    {
      question: 'Do you need skill to play Clash Royale?',
      answer: (
        <span>
          Yes, Clash Royale requires strategic thinking, quick reflexes, and proper card management. While some luck is involved, skillful deck-building and timing are key to consistent wins. Use our {' '}
          <a href="https://crdeckbuilder.com/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            Deck Builder
          </a> {' '}
          to improve your strategy.
        </span>
      ),
    },
    {
      question: 'Is it possible to have two evolution cards in Clash Royale?',
      answer: (
        <span>
          Currently, only one Evolution card can be included in your deck at a time. Check back for updates as new game features are added.
        </span>
      ),
    },
    {
      question: 'What was the first EVO card in Clash Royale?',
      answer: (
        <span>
          The first Evolution card introduced in Clash Royale was Skeleton King. It set the stage for more EVO cards that bring new gameplay mechanics and depth to the game.
        </span>
      ),
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <>
      <Helmet>
        <title>Clash Royale FAQs - Deck Building, Strategies & Safety</title>
        <meta name="description" content="Explore the most frequently asked questions about Clash Royale, including deck building tips, strategies, safety, and more." />
        <meta name="keywords" content="Clash Royale, Deck Building, Clash Royale FAQs, Best Decks, Beginner Decks, Clash Royale Safety, Deck Strategies" />
      </Helmet>
      
      <div className="min-h-screen w-full flex items-center justify-center bg-gradient-to-r from-purple-800 to-indigo-900 p-6">
        <div className="glassmorphism p-8 rounded-lg shadow-lg max-w-6xl w-full">
          <h1 className="text-4xl font-bold text-white mb-6 text-center">Clash Royale FAQs - Deck Building & Strategy</h1>
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <div key={index} className="border-b border-gray-700 pb-4">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="w-full flex justify-between items-center text-left text-white focus:outline-none"
                >
                  <span className="text-lg">{faq.question}</span>
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {activeIndex === index && <p className="mt-2 text-gray-300">{faq.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
