// src/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from './api/axios';
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [authState, setAuthState] = useState({
    token: null,
    user: null,
    isAuthenticated: false,
    loading: true,
    error: null,
  });

  // Load token and user from localStorage on initial load
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    if (token && user) {
      setAuthState({
        token,
        user: JSON.parse(user),
        isAuthenticated: true,
        loading: false,
        error: null,
      });
    } else {
      setAuthState({
        token: null,
        user: null,
        isAuthenticated: false,
        loading: false,
        error: null,
      });
    }
  }, []);

  // Login function
  const login = async (username, password) => {
    try {
      const response = await api.post('/api/auth/login', { username, password });
      const { token } = response.data;

      // Decode token to get user info
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decodedData = JSON.parse(window.atob(base64));

      const user = {
        id: decodedData.id,
        role: decodedData.role,
      };

      // Save to state and localStorage
      setAuthState({
        token,
        user,
        isAuthenticated: true,
        loading: false,
        error: null,
      });

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      // Navigate to Admin Deck Creator after successful login
      navigate('/secure-admin');

      return { success: true };
    } catch (error) {
      console.error('Login error:', error.response?.data);
      setAuthState({
        token: null,
        user: null,
        isAuthenticated: false,
        loading: false,
        error: error.response?.data?.error || 'Login failed',
      });
      return { success: false, error: error.response?.data?.error || 'Login failed' };
    }
  };

  // Logout function
  const logout = () => {
    setAuthState({
      token: null,
      user: null,
      isAuthenticated: false,
      loading: false,
      error: null,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
