// src/App.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import About from './components/About';
import AdminDeckCreator from './components/AdminDeckCreator';
import BestDeck from './components/BestDeck';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import ContactUs from './components/ContactUs';
import Login from './components/dbjfwiisfv';
import DeckBuilder from './components/DeckBuilder';
import DeckShop from './components/DeckShop';
import FAQs from './components/FAQs';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import PrivacyPolicy from './components/PrivacyPolicy';
import ProtectedRoute from './components/ProtectedRoute';
import TermsAndConditions from './components/TermsAndConditions';
import YouTubePage from './components/YouTube';



  function App() {
    useEffect(() => {
      console.clear(); // Clears the console when the app loads
    }, []);
  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-purple-800 to-indigo-900">
          <Helmet>
          <title>Clash Royale Deck Builder - Deck in One Click</title>
  <meta name="description" content="Build winning Clash Royale decks in seconds with our easy deck generator. Choose the best card combinations, analyze stats, and conquer the arena effortlessly. Try it now!" />
  <meta name="keywords" content="Clash Royale Deck Builder,cr deck builder, Clash Royale one-click deck builder, Clash Royale deck generator, Clash Royale strategy, deck synergy, best Clash Royale decks, elixir management, Clash Royale card combinations, Clash Royale Deck Shop" />

  <meta property="og:title" content="Clash Royale Deck Builder - Create Deck in One Click" />
  <meta property="og:description" content="Instantly generate and build optimized Clash Royale decks with our one-click deck builder. Analyze deck balance, elixir cost, and card synergies for the perfect strategy." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://crdeckbuilder.com" />
  <meta property="og:image" content="https://blog.webys.org/wp-content/uploads/2024/10/Untitled-design-92.png" />
  <meta property="og:site_name" content="Clash Royale Deck Builder" />
  <link rel="canonical" href="https://crdeckbuilder.com" />

  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Ultimate Clash Royale One-Click Deck Builder & Generator - Build Winning Decks" />
  <meta property="twitter:description" content="Use our one-click Clash Royale deck generator to create strategic card combinations instantly and improve your win rate." />
  <meta property="twitter:image" content="https://blog.webys.org/wp-content/uploads/2024/10/Untitled-design-92.png" />
          </Helmet>
          <Navbar />
          <main className="flex-grow max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <Routes>
              <Route path="/" element={<DeckBuilder />} />
              <Route path="/deck-shop" element={<DeckShop />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/best-deck" element={<BestDeck />} />
              <Route path="/about" element={<About />} />
              <Route
                path="/admin-panel-bdjdfjjd"
                element={
                  <ProtectedRoute roles={['admin', 'superadmin']}>
                    <AdminDeckCreator />
                  </ProtectedRoute>
                }
              />
              <Route path="/dbjfwiisfv" element={<Login />} />
              <Route path="/blog/:id" element={<BlogPost />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/youtube" element={<YouTubePage />} />
              <Route path="*" element={<div className="text-center text-white">404 - Page Not Found</div>} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
