// src/components/ContactUs.js
import React from 'react';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Clash Royale Deck Builder</title>
        <meta name="description" content="Contact us for any queries or support related to the Clash Royale Deck Builder web app. We are here to assist you with deck generation, editing, and strategies." />
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-800 to-indigo-900 p-6">
        <div className="glassmorphism p-8 rounded-lg shadow-lg max-w-3xl w-full">
          <h1 className="text-4xl font-bold text-white mb-6 text-center">Contact Us</h1>
          <p className="text-white mb-4 text-center">
            We'd love to hear from you! If you have any questions, suggestions, or need support, feel free to reach out to us using the contact form below.
          </p>

          <form className="space-y-6">
            <div>
              <label className="block text-white font-semibold mb-2" htmlFor="name">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full p-3 rounded-lg bg-white text-black"
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label className="block text-white font-semibold mb-2" htmlFor="email">
                Your Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-3 rounded-lg bg-white text-black"
                placeholder="Enter your email"
                required
              />
            </div>

            <div>
              <label className="block text-white font-semibold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                className="w-full p-3 rounded-lg bg-white text-black h-32"
                placeholder="Type your message here"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg transition-transform hover:scale-105"
            >
              Send Message
            </button>
          </form>

          <div className="mt-8 text-center">
            <p className="text-white">Or reach out to us at:</p>
            <p className="text-white font-bold">support@crdeckbuilder.com</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
