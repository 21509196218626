// src/components/PrivacyPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Clash Royale Deck Builder</title>
        <meta
          name="description"
          content="Read our privacy policy to understand how Clash Royale Deck Builder protects your personal data and ensures your privacy."
        />
        <meta
          name="keywords"
          content="Privacy Policy, Data Protection, User Privacy, Clash Royale Deck Builder, Personal Information, Privacy Practices, Security"
        />
        <meta property="og:title" content="Privacy Policy - Clash Royale Deck Builder" />
        <meta
          property="og:description"
          content="Learn how Clash Royale Deck Builder ensures your privacy and responsibly handles your data."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.yourwebsite.com/privacy-policy" />
        <meta property="og:image" content="https://www.yourwebsite.com/path-to-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Clash Royale Deck Builder" />
        <meta
          name="twitter:description"
          content="Clash Royale Deck Builder takes your privacy seriously. Learn more about our data protection practices."
        />
        <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-image.jpg" />
        <link rel="canonical" href="https://www.yourwebsite.com/privacy-policy" />
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-800 to-indigo-900 p-6">
        <div className="glassmorphism p-8 rounded-lg shadow-lg max-w-3xl w-full">
          <h1 className="text-4xl font-bold text-white mb-6 text-center">Privacy Policy</h1>

          <p className="text-white mb-4">
            At Clash Royale Deck Builder, your privacy is our top priority. This Privacy Policy outlines how we handle your data, what information we collect, and how we ensure the security of your personal information when you use our web app and services.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">1. Age Restrictions</h2>
          <p className="text-white mb-4">
            Our web app is intended for users aged 13 and older. We do not knowingly collect information from children under the age of 13 in compliance with the Children’s Online Privacy Protection Act (COPPA). If you are under the age of 13, please do not use our app or provide any personal information.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">2. Information We Do Not Collect</h2>
          <p className="text-white mb-4">
            Since our web app is fully open-source and does not require any user registration or login, we do not collect, store, or process any personal information from our users. You are free to use the app without providing any identifying details.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">3. Supercell’s Fan Content Policy Compliance</h2>
          <p className="text-white mb-4">
            Clash Royale Deck Builder strictly follows Supercell's Fan Content Policy to avoid copyright infringement. We do not charge users for access to game content, and all Supercell-related content is credited to Supercell. This is a fan-made tool, and we are not affiliated with Supercell. We do not modify Supercell’s logos or in-game assets, and our goal is to provide value to the Clash Royale community by offering unique tools and deck-building resources.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">4. Open-Source Commitment</h2>
          <p className="text-white mb-4">
            As an open-source project, all source code for our web app is publicly available. We invite users to review our code and contribute to its development. Our commitment to transparency ensures that your privacy is respected at all times.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">5. External Links</h2>
          <p className="text-white mb-4">
            Our website contains links to external websites (e.g., our blog or community pages). We are not responsible for the privacy practices or the content of these external sites. Please review the privacy policies of any third-party websites you visit.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">6. Changes to Our Privacy Policy</h2>
          <p className="text-white mb-4">
            We may update this Privacy Policy periodically to reflect changes in our practices or for legal or operational reasons. Any updates will be posted on this page, and your continued use of the web app constitutes your agreement to the revised policy.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">7. Contact Us</h2>
          <p className="text-white mb-4">
            If you have any questions or concerns about this Privacy Policy or our data protection practices, feel free to contact us through our blog or community forums. We are committed to ensuring your privacy and addressing any concerns you may have.
          </p>

          <p className="text-white mb-4">
            Thank you for using the Clash Royale Deck Builder. We appreciate your trust in our service.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
