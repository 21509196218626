// src/components/DeckBuilder.js
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async'; // Import React Helmet for SEO
import { FaClipboard, FaInfoCircle, FaMagic, FaPlay, FaPlus, FaSearch, FaTrash } from 'react-icons/fa'; // Updated icons




const DeckBuilder = () => {
  const [cards, setCards] = useState([]);
  const [deck, setDeck] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [elixirClass, setElixirClass] = useState('balanced');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCards, setFilteredCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAvailableCards, setShowAvailableCards] = useState(false); // Toggle state
  const [availableSearchTerm, setAvailableSearchTerm] = useState(''); // Search for available cards
  const [filteredAvailableCards, setFilteredAvailableCards] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal state for how-to guide
  const [apiError, setApiError] = useState(null); // API error handling


  const API_KEY = process.env.REACT_APP_API_KEY;  

  // Fetch all cards when the component mounts
  useEffect(() => {
    fetchCards();
    setShowModal(true); // Show the modal when the page loads
  }, []);

  // Decryption function
  const decryptData = (encryptedData) => {
    try {
      const [ivHex, encryptedText] = encryptedData.split(':');
      if (!ivHex || !encryptedText) throw new Error('Invalid encrypted data format');

      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedText);
      const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY.padEnd(32, ' '));

      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        key,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );

      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) throw new Error('Failed to decrypt the data');
      return JSON.parse(decryptedText);
    } catch (error) {
      console.error('Decryption Error:', error.message);
      setError('Failed to decrypt data.');
      return null;
    }
  };

  // Fetch cards from the API
  const fetchCards = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('https://clashroyale.qwest.crdeckbuilder.com/api/cards', {
        method: 'GET',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching cards: ${response.statusText}`);
      }

      const { data: encryptedData } = await response.json();
      const decryptedData = decryptData(encryptedData);

      if (decryptedData) {
        setCards(decryptedData);
      } else {
        throw new Error('Error decrypting card data');
      }
    } catch (error) {
      setError(`Failed to fetch cards: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const autoGenerateDeck = async () => {
    setApiError(null);
    try {
      const url = `https://clashroyale.qwest.crdeckbuilder.com/api/generate_deck?elixirClass=${elixirClass}`;
      const response = await fetch(url, {
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error generating deck: ${response.statusText}`);
      }

      const { data: encryptedData } = await response.json();
      const decryptedData = decryptData(encryptedData);

      if (!decryptedData || !Array.isArray(decryptedData.deck)) {
        throw new Error('Invalid deck data received');
      }

      const mergedDeck = selectedCard ? [selectedCard, ...decryptedData.deck].slice(0, 8) : decryptedData.deck.slice(0, 8);
      setDeck(mergedDeck);
      calculateDeckStats(mergedDeck);
    } catch (error) {
      setApiError(`Deck generation failed: ${error.message}`);
    }
  };

  useEffect(() => {
    if (deck.length > 0) {
      calculateDeckStats(deck);
    } else {
      setStats(null);
    }
  }, [deck]);

  // Calculate deck stats based on selected cards
  const calculateDeckStats = async (currentDeck) => {
    try {
      const response = await fetch('https://clashroyale.qwest.crdeckbuilder.com/api/deck/stats', {
        method: 'POST',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ deck: currentDeck }),
      });

      if (!response.ok) {
        throw new Error(`Failed to calculate deck stats: ${response.statusText}`);
      }

      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Error calculating deck stats:', error);
    }
  };

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const addCardToDeck = (card) => {
    if (deck.length < 8) {
      const updatedDeck = [...deck, card];
      setDeck(updatedDeck);
    } else {
      alert('Deck can only contain 8 cards.');
    }
  };

  const removeCardFromDeck = (index) => {
    const updatedDeck = [...deck];
    updatedDeck.splice(index, 1);
    setDeck(updatedDeck);
  };

  const copyDeckToClashRoyale = () => {
    if (!deck || deck.length === 0) {
      alert('Please select a valid deck.');
      return;
    }

    const deckCardIds = deck.map((card) => card.id);
    if (deckCardIds.length !== 8) {
      alert('Please select exactly 8 cards for the deck.');
      return;
    }

    const deckIdsString = deckCardIds.join(';');
    const slots = '0;0;0;0;0;0;0;0';
    const tt = '159000000';
    const league = 'Royals';

    const deckLink = `https://link.clashroyale.com/en/?clashroyale://copyDeck?deck=${deckIdsString}&slots=${slots}&tt=${tt}&l=${league}`;
    window.open(deckLink, '_blank');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setFilteredCards([]);
    } else {
      const filtered = cards.filter((card) =>
        card.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredCards(filtered);
    }
  };

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    setSearchTerm('');
    setFilteredCards([]);
  };

  // Filter available cards based on search input
  useEffect(() => {
    const filtered = cards.filter((card) =>
      card.name.toLowerCase().includes(availableSearchTerm.toLowerCase())
    );
    setFilteredAvailableCards(filtered);
  }, [availableSearchTerm, cards]);

  return (


<>
<Helmet>
  <title>Clash Royale Deck Builder - Create Deck in One Click</title>
  <meta name="description" content="Build powerful Clash Royale decks with our one-click deck generator. Analyze elixir cost, balance strategies, and dominate the arena now!" />
  
  <meta name="keywords" content="Clash Royale Deck Builder, CR deck builder, Clash Royale one-click deck builder, Clash Royale deck generator, best Clash Royale decks, deck synergy, elixir management, Clash Royale card combinations, Clash Royale strategy, deck builder app, Clash Royale deck builder AI, free Clash Royale deck builder, automatic Clash Royale deck builder, Clash Royale deck builder 2024, Clash Royale deck builder unblocked, Clash Royale deck builder Reddit, Clash Royale deck builder Arena 4" />

  <meta property="og:title" content="Ultimate Clash Royale Deck Builder - Build Winning Decks Instantly" />
  <meta property="og:description" content="Generate and build optimized Clash Royale decks in seconds with our one-click deck builder. Analyze elixir cost, card synergies, and deck balance for the perfect strategy." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://crdeckbuilder.com" />
  <meta property="og:image" content="https://blog.webys.org/wp-content/uploads/2024/10/Untitled-design-92.png" />
  <meta property="og:site_name" content="Clash Royale Deck Builder" />

  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Ultimate Clash Royale Deck Builder & Generator - Build Winning Decks Instantly" />
  <meta property="twitter:description" content="Create optimized Clash Royale decks instantly with our deck builder. Improve your strategy and win rate with perfect card combinations." />
  <meta property="twitter:image" content="https://blog.webys.org/wp-content/uploads/2024/10/Untitled-design-92.png" />
</Helmet>



    <div className="min-h-screen bg-gradient-to-r from-purple-800 to-indigo-900 p-4 sm:p-6 w-full text-white">
      <div className="flex flex-col md:flex-row items-center justify-between mb-6">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold flex items-center mb-4 md:mb-0">
          Clash Royale Deck Builder
          <button
            onClick={toggleModal}
            className="ml-2 p-2 bg-purple-700 hover:bg-purple-800 text-yellow-400 rounded-full focus:outline-none transition duration-150 ease-in-out"
            aria-label="How to Use"
          >
            <FaInfoCircle className="text-xl md:text-2xl" />
          </button>
        </h1>
      </div>

      <p className="text-base sm:text-lg md:text-xl mt-2 text-center md:text-left mb-6" style={{ border: "none", background: "none" }}>
        Build the ultimate Clash Royale deck by selecting your favorite cards and analyzing their synergy to create a winning strategy
      </p>

      {showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="relative bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-4 sm:p-6 md:p-8 rounded-xl shadow-2xl w-10/12 sm:w-2/3 md:w-1/2 lg:w-1/3 max-w-lg h-auto">
      
      {/* X close button with background */}

      <h2 className="text-base sm:text-lg md:text-xl font-semibold mb-4 text-center" style={{ border: "none", background: "none" }}>How to Use the Deck Builder</h2>
      <ol className="list-decimal list-inside space-y-2 text-xs sm:text-sm md:text-base">
        {/* List items with border and decreased opacity */}
        <li className="border-b border-gray-400 border-opacity-50 pb-1">Select the elixir class from the dropdown.</li>
        <li className="border-b border-gray-400 border-opacity-50 pb-1">Use the search bar to find a specific card.</li>
        <li className="border-b border-gray-400 border-opacity-50 pb-1">Click on a card to select it for your deck.</li>
        <li className="border-b border-gray-400 border-opacity-50 pb-1">You can add up to 8 cards in your deck.</li>
        <li className="border-b border-gray-400 border-opacity-50 pb-1">Click "Generate Deck" to auto-generate a balanced deck.</li>
        <li className="border-b border-gray-400 border-opacity-50 pb-1">View your deck's stats, including average elixir cost and win rate.</li>
        <li className="border-b border-gray-400 border-opacity-50 pb-1">Click "Copy Deck" to export it to Clash Royale.</li>
  {/* Close button */}
<a href="https://youtu.be/7W1vmg9ezdg" target="_blank" rel="noopener noreferrer">
  <button
    onClick={toggleModal}
    className="mt-6 flex items-center justify-center bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg shadow-md transition duration-150 ease-in-out text-sm sm:text-base w-full"
  >
    Watch Tutorial <FaPlay className="ml-2" />
  </button>
</a>
      </ol>
      
      {/* Close button */}
      <button
        onClick={toggleModal}
        className="mt-6 flex items-center justify-center bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg shadow-md transition duration-150 ease-in-out text-sm sm:text-base w-full"
      >
        Close <FaTrash className="ml-2" />
      </button>
    </div>
  </div>
)}



      <div className="flex flex-col sm:flex-row items-center justify-center mb-8 space-y-4 sm:space-y-0 sm:space-x-4">
        <select
          value={elixirClass}
          onChange={(e) => setElixirClass(e.target.value)}
          className="p-2 rounded-lg bg-white bg-opacity-20 text-black focus:outline-none focus:ring-2 focus:ring-purple-600 w-full sm:w-auto text-sm sm:text-base"
        >
          <option value="low">Low Elixir Deck (0-3.5)</option>
          <option value="high">High Elixir Deck (3.5-7)</option>
          <option value="balanced">Balanced Elixir Deck</option>
        </select>

        <div className="relative w-full sm:w-auto flex-grow">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for a card"
            className="pl-10 pr-4 py-2 rounded-lg bg-white bg-opacity-20 text-white w-full focus:outline-none focus:ring-2 focus:ring-purple-600 text-sm sm:text-base"
          />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300" />
        </div>

        <button
          onClick={autoGenerateDeck}
          className="flex items-center justify-center bg-orange-600 hover:bg-purple-700 text-white px-4 sm:px-6 py-2 rounded-lg shadow-lg transition duration-150 ease-in w-full sm:w-auto text-sm sm:text-base"
        >
          <FaMagic className="mr-2" />
          Generate Deck
        </button>
      </div>

      {filteredCards.length > 0 && (
        <ul className="max-w-2xl mx-auto bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-4 mb-8">
          {filteredCards.map((card) => (
            <li
              key={card.id}
              onClick={() => handleSelectCard(card)}
              className="flex items-center justify-between p-2 sm:p-3 hover:bg-white hover:bg-opacity-20 cursor-pointer rounded-lg transition duration-150 ease-in-out text-sm sm:text-base"
            >
              <span>{card.name}</span>
              <FaPlus className="text-green-400" />
            </li>
          ))}
        </ul>
      )}

      {selectedCard && (
        <div className="flex items-center justify-center mb-8">
          <div className="flex items-center bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg px-4 sm:px-6 py-2 sm:py-3 rounded-lg shadow-lg">
            <span className="mr-4 text-sm sm:text-base">Selected Card: {selectedCard.name}</span>
            <FaTrash
              className="text-red-400 cursor-pointer"
              onClick={() => setSelectedCard(null)}
              title="Remove Selected Card"
            />
          </div>
        </div>
      )}

<div className="mb-12">
  <h2 className="text-xl sm:text-2xl font-semibold text-center mb-6">Your Deck</h2>
  <div className="grid grid-cols-4 gap-2 sm:gap-4 justify-center">
    {deck.length > 0 ? (
      deck.map((card, index) => (
        <div
          key={card.id}
          className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-2 sm:p-4 rounded-lg flex flex-col items-center shadow-lg"
        >
          <span className="text-xs sm:text-sm mb-1 sm:mb-2"> {card.level}</span>
          <h4
            className="text-sm sm:text-base font-medium mb-1 sm:mb-2 text-center truncate w-full"
            title={card.name}
            style={{
              background: "none", // No background color
              color: "#fff", // White text for readability
              backdropFilter: "none", // Disable backdrop filter on this element
              zIndex: 1, // Make sure it is on top
              textShadow: "0 1px 2px rgba(0, 0, 0, 0.5)", // Text shadow for readability
              border: "none",
            }}
          >
            {card.name}
          </h4>
          <img
            className="w-10 h-10 sm:w-16 sm:h-16 object-contain mb-2 sm:mb-4"
            src={card.iconUrls.evolutionMedium || card.iconUrls.medium}
            alt={card.name}
          />
          <div className="mt-auto">
            <button
              onClick={() => removeCardFromDeck(index)}
              className="flex items-center bg-red-500 hover:bg-red-600 text-white px-2 sm:px-3 py-1 rounded-full transition duration-150 ease-in-out text-xs sm:text-sm"
            >
              <FaTrash className="mr-1" />
              Remove
            </button>
          </div>
        </div>
      ))

   

          ) : (
            Array.from({ length: 8 }).map((_, i) => (
              <div key={i} className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-2 sm:p-4 rounded-lg flex flex-col items-center justify-center shadow-lg">
                <h4 className="text-sm sm:text-base mb-1">No Card</h4>
                <p className="text-xs sm:text-sm text-gray-300">Empty Slot</p>
              </div>
            ))
          )}
        </div>

        <div className="flex justify-center mt-6">
          <button
            onClick={copyDeckToClashRoyale}
            className="flex items-center bg-orange-600 hover:bg-purple-700 text-white px-4 sm:px-6 py-2 rounded-lg shadow-lg transition duration-150 ease-in-out text-sm sm:text-base"
          >
            <FaClipboard className="mr-2" />
            Copy Deck
          </button>
        </div>

        {stats && (
  <div className="mt-6 max-w-2xl mx-auto bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-4 sm:p-6">
    <p className="text-sm sm:text-base">
      <strong>Average Elixir Cost:</strong> {stats.average_elixir}
    </p>
    <p className="text-sm sm:text-base">
      <strong>Elixir Class:</strong> {stats.elixir_class}
    </p>
    <p className="text-sm sm:text-base">
      <strong>Win Rate:</strong> {stats.win_rate}
    </p>
    <div className="mt-4">
      <strong className="text-sm sm:text-base">Tips:</strong>
      <ul className="list-none p-0 m-0 mt-2 space-y-1 text-xs sm:text-sm">
        {stats.tips.map((tip, index) => (
          <li
            key={index}
            className="p-2 sm:p-3 bg-[#3c2a6771] text-white cursor-pointer border-b border-gray-300 transition duration-200 ease-in-out hover:bg-[#2b2251]"
          >
            {tip}
          </li>
        ))}
      </ul>
            </div>
          </div>
        )}
      </div>

      {/* Available Cards Section */}
      <h2 className="text-2xl font-semibold text-white text-center mb-4" >Available Cards</h2>
      <div className="toggle-button flex justify-center mb-6">
        <button
          onClick={() => setShowAvailableCards(!showAvailableCards)}
          className="flex items-center bg-orange-600 hover:bg-purple-700 text-white px-6 py-2 rounded-lg shadow-lg transition duration-150 ease-in-out"
        >
          {showAvailableCards ? 'Hide All Cards' : 'Show All Cards'}
        </button>
      </div>

      {showAvailableCards && (
  <>
    <div className="flex justify-center mb-6">
      <div className="relative w-full max-w-md">
        <input
          type="text"
          value={availableSearchTerm}
          onChange={(e) => setAvailableSearchTerm(e.target.value)}
          placeholder="Search available cards"
          className="pl-10 pr-4 py-2 rounded-lg bg-white bg-opacity-10 text-white w-full focus:outline-none focus:ring-2 focus:ring-blue-600 backdrop-filter backdrop-blur-lg text-sm sm:text-base"
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300" />
      </div>
    </div>

          <div className="grid grid-cols-3 gap-2 sm:gap-4 max-w-7xl mx-auto max-h-96 overflow-y-auto p-4 bg-black bg-opacity-20 rounded-lg">
            {filteredAvailableCards.map((card) => (
              <div key={card.id} className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-2 sm:p-4 rounded-lg flex flex-col items-center shadow-lg">
                <h4 className="text-sm sm:text-base font-medium mb-1 sm:mb-2">{card.name}</h4>
                <img
                  className="w-10 h-10 sm:w-16 sm:h-16 object-contain mb-2 sm:mb-4"
                  src={card.iconUrls.evolutionMedium || card.iconUrls.medium}
                  alt={card.name}
                />
                <button
                  onClick={() => addCardToDeck(card)}
                  className="flex items-center bg-orange-600 hover:bg-orange-700 text-white px-2 sm:px-4 py-1 rounded-full transition duration-150 ease-in-out text-xs sm:text-sm"
                >
                  <FaPlus className="mr-1" />
                  Add to Deck
                </button>
              </div>
            ))}
          </div>
        </>
      )}

      {error && (
        <div className="text-center text-red-500 mt-6 text-sm sm:text-base">
          <p>{error}</p>
        </div>
      )}

      {apiError && (
        <div className="text-center text-red-500 mt-6 text-sm sm:text-base">
          <p>{apiError}</p>
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center mt-6">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-t-4 border-b-4 border-purple-500"></div>
        </div>
      )}
    </div>
    </>
  );
};

export default DeckBuilder;
