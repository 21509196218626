import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Login.css'; // Optional for styling

const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [isRegistering, setIsRegistering] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [localError, setLocalError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLocalError('');
    setSuccessMessage('');

    if (!username || !password) {
      setLocalError('Please enter both username and password');
      return;
    }

    try {
      if (isRegistering) {
        // Handle Registration
        const response = await fetch('/api/auth/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password, role: 'admin' }),
        });

        const result = await response.json();

        if (response.ok) {
          setSuccessMessage('Registration successful. Please log in.');
          setIsRegistering(false);
        } else {
          setLocalError(result.error || 'Registration failed.');
        }
      } else {
        // Handle Login
        const result = await login(username, password);
        if (result.success) {
          navigate('/secure-admin');
        } else {
          setLocalError(result.error || 'Login failed.');
        }
      }
    } catch (error) {
      setLocalError('Server error during registration or login.');
    }
  };

  return (
    <div className="login-container">
      <h2>{isRegistering ? 'Admin Register' : 'Admin Login'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={username}
          onChange={onChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={onChange}
          required
        />
        <button type="submit">{isRegistering ? '' : 'Login'}</button>
      </form>
      {successMessage && <p className="success">{successMessage}</p>}
      {localError && <p className="error">{localError}</p>}
      <button onClick={() => setIsRegistering(!isRegistering)}>
        {isRegistering ? 'Already have an account? Login' : ''}
      </button>
    </div>
  );
};

export default Login;
