// src/components/Navbar.js
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { FaBook, FaShoppingCart, FaStar, FaInfoCircle, FaTools, FaMagic, FaDonate, FaTimes, FaBars } from 'react-icons/fa'; // Import necessary icons
import logo from '../logo.png';

const Navbar = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const navItems = [
    { name: 'Deck Builder', path: '/', icon: <FaMagic /> }, // Renamed 'Home' to 'Deck Builder'
    { name: 'Deck Shop', path: '/deck-shop', icon: <FaShoppingCart /> },
    { name: 'Blog', path: '/blog', icon: <FaBook /> },
    { name: 'Best Deck', path: '/best-deck', icon: <FaStar /> }, // Changed icon to FaStar
    { name: 'About', path: '/about', icon: <FaInfoCircle /> },
  ];

  return (
<nav className="bg-gradient-to-r from-purple-800 to-indigo-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Title */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
            <img className="h-8 w-auto sm:h-10 mr-2" src={logo} alt="Clash Royale Logo" />
            <span className="text-white font-bold text-lg sm:text-xl whitespace-nowrap">CR🌟Deck🌟Builder</span>
            </Link>
          </div>

          {/* Navigation Links for Desktop and Large Tablets */}
          <div className="hidden lg:block">
            <div className="ml-10 flex items-center space-x-4">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out whitespace-nowrap"
                >
                  <span className="mr-1">{item.icon}</span>
                  {item.name}
                </Link>
              ))}
              {isAuthenticated && (user.role === 'admin' || user.role === 'superadmin') && (
                <Link
                  to="/admin-panel"
                  className="flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out whitespace-nowrap"
                >
                  <FaTools className="mr-1" />
                  Admin Panel
                </Link>
              )}
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=KFF3TCLVWTB5U"
                className="flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out whitespace-nowrap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDonate className="mr-1" />
                Donate
              </a>
            </div>
          </div>

          {/* Mobile and Small Tablet Menu Button */}
          <div className="lg:hidden">
            <button
              onClick={toggleNavbar}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-800 focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <FaTimes className="block h-6 w-6" /> : <FaBars className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile and Small Tablet Navigation Menu */}
      <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className="flex items-center text-gray-300 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-150 ease-in-out"
              onClick={toggleNavbar}
            >
              <span className="mr-2">{item.icon}</span>
              {item.name}
            </Link>
          ))}
          {isAuthenticated && (user.role === 'admin' || user.role === 'superadmin') && (
            <Link
              to="/admin-panel"
              className="flex items-center text-gray-300 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-150 ease-in-out"
              onClick={toggleNavbar}
            >
              <FaTools className="mr-2" />
              Admin Panel
            </Link>
          )}
          <a
            href="https://www.paypal.com/donate/?hosted_button_id=KFF3TCLVWTB5U"
            className="flex items-center text-gray-300 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-150 ease-in-out"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggleNavbar}
          >
            <FaDonate className="mr-2" />
            Donate
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
