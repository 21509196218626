import axios from 'axios';
import CryptoJS from 'crypto-js'; // Ensure you have crypto-js installed
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './Blog.css';

// Helper function to decrypt data using AES encryption
const decryptData = (encryptedData) => {
  try {
    const [ivHex, encryptedText] = encryptedData.split(':');
    if (!ivHex || !encryptedText) throw new Error('Invalid encrypted data format');

    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedText);
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY.padEnd(32, ' '));

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      key,
      { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedText) throw new Error('Failed to decrypt the data');
    return JSON.parse(decryptedText);
  } catch (error) {
    console.error('Decryption Error:', error.message);
    return null;
  }
};

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 15; // Limit posts per page
  const navigate = useNavigate();

  const API_KEY = process.env.REACT_APP_API_KEY; // Store API Key securely in environment variables

  // Fetch blog posts from backend with AES decryption
  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('https://clashroyale.qwest.crdeckbuilder.com/api/blog', {
          headers: {
            'x-api-key': API_KEY,
          },
        });

        if (response.status !== 200) {
          throw new Error(`Failed to fetch blog posts: ${response.statusText}`);
        }

        const { data: encryptedData } = response.data;
        if (!encryptedData) throw new Error('No encrypted data received');

        const decryptedData = decryptData(encryptedData);
        if (decryptedData) {
          // Sort posts by createdAt in descending order (newest posts first)
          const sortedPosts = decryptedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setPosts(sortedPosts); // Set sorted posts
        } else {
          throw new Error('Error decrypting blog post data');
        }
      } catch (error) {
        console.error('API Error:', error.message);
        setApiError(`Failed to fetch blog posts: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  // Function to handle "Read More" click and navigate to blog post page
  const handleReadMore = (post) => {
    navigate(`/blog/${post.id}`);
  };

  // Calculate the current posts to display based on the page number
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Function to change the page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-page-container">
      <Helmet>
        <title>Blog - Latest Insights</title>
        <meta name="description" content="Explore our latest blog posts on a wide range of topics" />
        <meta name="keywords" content="Blog, Articles, Insights, Technology, Lifestyle, Latest Updates" />
        <meta property="og:title" content="Blog - Latest Insights" />
        <meta property="og:description" content="Read our latest blog posts covering various trending topics." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="https://blog.webys.org/wp-content/uploads/2024/10/Untitled-design-92.png" /> {/* Replace with actual image */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blog - Latest Insights" />
        <meta name="twitter:description" content="Check out our newest blog posts and updates." />
        <meta name="twitter:image" content="path_to_featured_image.jpg" /> {/* Replace with actual image */}
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="ads-container">
        {/* Placeholder for horizontal ads */}
        <div className="ad ad-top"></div>
      </div>

      <h1 className="blog-header">Latest Blog Posts</h1>

      {loading ? (
        <>
          <Helmet>
            <title>Loading Blog Posts...</title>
            <meta name="description" content="Our blog is loading. Stay tuned for the latest updates on a wide range of topics." />
          </Helmet>
          <p>Loading...</p>
        </>
      ) : apiError ? (
        <>
          <Helmet>
            <title>Blog Error</title>
            <meta name="description" content={apiError} />
          </Helmet>
          <p className="error-message">{apiError}</p>
        </>
      ) : (
        <div className="blog-posts-grid">
          {currentPosts.length === 0 ? (
            <p>No blog posts available</p>
          ) : (
            currentPosts.map((post) => (
              <div key={post.id} className="blog-post-full glass-effect">
                <Helmet>
                  <title>Clash Royale Latest Post</title>
                  <meta name="description" content={post.content.substring(0, 150)} />
                  <meta property="og:title" content={post.title} />
                  <meta property="og:description" content={post.content.substring(0, 150)} />
                  <meta property="og:url" content={`${window.location.origin}/blog/${post.id}`} />
                  <link rel="canonical" href={`${window.location.origin}/blog/${post.id}`} />
                </Helmet>

                {post.imageUrl && (
                  <img src={post.imageUrl} alt={post.title} className="blog-image-full" />
                )}

                <div className="blog-content">
                  <h2 className="blog-title">{post.title}</h2>
                  <p
                    className="blog-description"
                    dangerouslySetInnerHTML={{
                      __html: post.content.substring(0, 150) + '...',
                    }}
                  ></p>
                  <button onClick={() => handleReadMore(post)} className="read-more-btn item-center bg-purple-500">
                    Read More
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}

      {/* Pagination Component */}
      <div className="pagination ">
        {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, i) => (
          <button
            key={i}
            className={`pagination-button mr-0 ml-3 mt-4 space-x-2 bg-purple-600 border-solid	 text-white  ${currentPage === i + 1 ? 'active' : ''}`}
            onClick={() => paginate(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <div className="ads-container">
        {/* Placeholder for horizontal ads */}
        <div className="ad ad-bottom"></div>
      </div>
    </div>
  );
};

export default Blog;
