import React from 'react';
import { FaDonate, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import './footer.css';

// Importing all the pages

const Footer = () => {
  return (

    
    <footer className="bg-gradient-to-r from-purple-800 via-indigo-900 to-purple-900 text-white py-8">
      
      <div className="container mx-auto px-6">
        
        {/* Main Footer Content */}
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center">
          
          {/* About Section */}
          <div className="mb-6 lg:mb-0 text-center" style={{ border: "none", background: "none" }}>
            <h2 className="text-xl font-bold mb-2" style={{ border: "none", background: "none" }}>Clash Royale Deck Builder</h2>
            <p className="text-gray-300 max-w-xs mx-auto" style={{ border: "none", background: "none" }}>
              Enhance your Clash Royale experience by building, customizing, and optimizing your decks with ease.
            </p>
          </div>
          
          {/* Navigation Links */}
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-1 sm:space-y-0 sm:space-x-4 mb-6 lg:mb-0">
            <Link to="/contact-us" className="text-gray-300 hover:text-white transition-colors">
              Contact-us
            </Link>
            <Link to="/privacy-policy" className="text-gray-300 hover:text-white transition-colors">
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" className="text-gray-300 hover:text-white transition-colors">
              Terms & Conditions
            </Link>
            <Link to="/faqs" className="text-gray-300 hover:text-white transition-colors">
              FAQs
            </Link>
            <Link to="/youtube" className="text-gray-300 hover:text-white transition-colors">
              YouTube
            </Link>
            <a 
  href="https://www.paypal.com/donate/?hosted_button_id=KFF3TCLVWTB5U" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-gray-300 hover:text-white transition-colors"
>
  Donate
</a>

          </div>
          
          {/* Social Media Icons + Donate Button */}
          <div className="flex justify-center space-x-4 mb-6 lg:mb-0">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors" aria-label="Facebook">
              <FaFacebookF size={20} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors" aria-label="Twitter">
              <FaTwitter size={20} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors" aria-label="Instagram">
              <FaInstagram size={20} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors" aria-label="LinkedIn">
              <FaLinkedinIn size={20} />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors" aria-label="YouTube">
              <FaYoutube size={20} />
            </a>
            <a href="mailto:support@crdeckbuilder.com" className="text-gray-300 hover:text-white transition-colors" aria-label="Email">
              <FaEnvelope size={20} />
            </a>
            {/* Donate Button */}
            <a href="https://www.paypal.com/donate/?hosted_button_id=KFF3TCLVWTB5U" target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-300 hover:text-white transition-colors" aria-label="Donate">
              <FaDonate size={20} />
            </a>
          </div>
        </div>
        
        {/* Divider */}
        <div className="border-t border-gray-700 my-6"></div>
        
        {/* Bottom Footer Content */}
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center text-center" style={{ border: "none", background: "none" }}>
          <p className="text-gray-400" style={{ margin: '0 auto' }}>
              This project is fan-made and is in no way affiliated with or endorsed by Supercell. Supercell is not responsible for its content. To learn more, please check out their <a href="https://supercell.com/en/fan-content-policy/" className="underline">Fan Content Policy</a>.
          </p>
          <p className="text-gray-400 mt-4 lg:mt-0">
              Designed and Developed by <span className="font-bold"> <a href="https://www.linkedin.com/in/yogesh-singh-479a38262/">Yogesh Singh </a></span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
