import CryptoJS from 'crypto-js';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { FaClipboard, FaInfoCircle, FaMagic, FaPlus, FaSearch, FaTrash } from 'react-icons/fa'; // Updated icons

import { Helmet } from 'react-helmet-async'; // React Helmet for SEO
import './Deckshop.css';

// Lazy loaded component
const Cards = lazy(() => import('./Cards'));

const categories = [
  'New Deck', 'Best Deck', 'Popular Deck', 'High Elixir Deck', 'Low Elixir Deck', 'Balanced Deck',
  'Beatdown Decks', 'Control Decks', 'Most Winning Deck', 'Chip Damage Decks', 'Cycle Decks',
  'Spell Bait Decks', 'Air Decks', 'Swarm Decks', 'Hybrid Decks', 'Pump Decks', 'Defensive Decks'
];

export default function DeckShop() {
  const [decks, setDecks] = useState([]);
  const [adsVisible, setAdsVisible] = useState(true);
  const [showPopupAd, setShowPopupAd] = useState(false);
  const [bannerAdHtml, setBannerAdHtml] = useState('');
  const [popupAdHtml, setPopupAdHtml] = useState('');
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // For lazy loading
  const API_KEY = process.env.REACT_APP_API_KEY;
  const CACHE_KEY = 'deckShopData';

  useEffect(() => {
    const cachedDecks = localStorage.removeItem(CACHE_KEY);
    if (cachedDecks) {
      setDecks(JSON.parse(cachedDecks));
    } else {
      fetchDecks();
    }



    const timer = setTimeout(() => {
      setShowPopupAd(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const decryptData = (encryptedData) => {
    try {
      const [ivHex, encryptedText] = encryptedData.split(':');
      if (!ivHex || !encryptedText) throw new Error('Invalid encrypted data format');

      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedText);
      const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY.padEnd(32, ' '));

      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        key,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );

      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error('Decryption Error:', error.message);
      setApiError('Failed to decrypt data.');
      return null;
    }
  };

  const fetchDecks = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://clashroyale.qwest.crdeckbuilder.com/api/deck-shop', {
        method: 'GET',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching decks: ${response.statusText}`);
      }

      const responseData = await response.json();
      const decryptedData = decryptData(responseData.data);

      if (decryptedData) {
        setDecks(decryptedData);
        localStorage.setItem(CACHE_KEY, JSON.stringify(decryptedData));
      }
    } catch (error) {
      setApiError(`Failed to fetch decks: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };



  const filterDecks = (category) => {
    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return decks.filter(deck =>
        deck.category === category &&
        (deck.deckName.toLowerCase().includes(lowerCaseSearchQuery) ||
          deck.deck.some(card => card.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
          deck.stats.averageElixirCost.toString().includes(lowerCaseSearchQuery))
      );
    }
    return decks.filter(deck => deck.category === category);
  };

  const renderDecksByCategory = (category) => {
    const categoryDecks = filterDecks(category);
    const currentDecks = categoryDecks.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    if (currentDecks.length === 0) return null;

    return (
      <div key={category} className="mb-8 p-6 rounded-lg glass-container">
        <h2 className="text-3xl font-bold mb-4 text-white">{category}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentDecks.map((deckItem) => (
            <Suspense fallback={<div>Loading Cards...</div>}>
              <Cards deckItem={deckItem} />
            </Suspense>
          ))}
        </div>
        {categoryDecks.length > itemsPerPage && (
          <button
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className="mt-4 mx-auto block px-4 py-2 bg-purple-500 text-white rounded"
          >
            Load More
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Deck Shop - Find the Best Clash Royale Decks for Your Next Battle</title>
        <meta
          name="description"
          content="Discover the best Clash Royale decks in our Deck Shop. Browse categories like Best Decks, Cycle Decks, and more."
        />
        <meta name="keywords" content="
clash royale deck, clash royale decks, clash royale deck builder
, clash royale deck checker, clash royale decks arena 8, clash royale deck archetypes, clash royale decks 2024, clash royale deck finder, clash royale deck generator, clash royale decks arena 6
, clash royale decks arena 12," />
      </Helmet>

      <div className="mb-8 p-6 rounded-lg text-white">
        <h1 className="text-4xl font-bold mb-4">Deck Shop</h1>
        <p className="text-xl text-center">Find the perfect deck for your next battle!</p>
      </div>

      {apiError && <div className="text-red-500 mb-4">{apiError}</div>}

      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
    <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-8">
      <div className="relative w-full md:w-64">
        <input
          type="text"
          placeholder="Search decks or cards"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-md bg-white bg-opacity-20 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-600 backdrop-filter backdrop-blur-lg"
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
      </div>
            
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full md:w-64 px-4 py-2 border rounded-md"
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {categories
            .filter((category) => !selectedCategory || category === selectedCategory)
            .map(renderDecksByCategory)}
        </>
      )}

      {adsVisible && (
        <div className="my-4" dangerouslySetInnerHTML={{ __html: bannerAdHtml }} />
      )}
    </div>
  );
}
