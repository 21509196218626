// src/components/TermsAndConditions.js
import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions - Clash Royale Deck Builder</title>
        <meta name="description" content="Read the terms and conditions for using our Clash Royale deck builder web app. We provide a one-click deck generation, deck editing, and a deck shop with open-source functionality." />
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-800 to-indigo-900 p-6">
        <div className="glassmorphism p-8 rounded-lg shadow-lg max-w-3xl w-full">
          <h1 className="text-4xl font-bold text-white mb-6 text-center">Terms & Conditions</h1>
          <p className="text-white mb-4">
            Welcome to our Clash Royale Deck Builder Web App. By accessing or using this website, you agree to be bound by the following terms and conditions. Please read them carefully before proceeding.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">1. General Use</h2>
          <p className="text-white mb-4">
            Our web app provides a platform to generate Clash Royale decks with just one click. You can select from balanced, low-elixir, or high-elixir decks and easily copy them to the game. Additionally, you can edit your decks while generating them by adding or removing cards. 
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">2. Deck Shop and Blog</h2>
          <p className="text-white mb-4">
            We offer a Deck Shop where users can explore and browse various Clash Royale decks categorized for different playstyles and strategies. We also have a blog page where we post updates, tips, and insights about Clash Royale deck strategies. All information provided in the blog is for general information purposes only.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">3. Open Source & No Data Collection</h2>
          <p className="text-white mb-4">
            This web app is fully open-source and does not require user registration or login. We do not collect, store, or process any personal information or data from our users. The app is designed to be accessible without the need to share any personal details.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">4. Limitation of Liability</h2>
          <p className="text-white mb-4">
            We make every effort to ensure that the information and functionality provided by the web app are accurate and reliable. However, we are not responsible for any potential inaccuracies or issues that may arise when using the decks generated by the app. Use the app at your own risk.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">5. Intellectual Property</h2>
          <p className="text-white mb-4">
            All content and functionality available through this web app, including deck generation features, deck shop content, and blog posts, are the intellectual property of their respective creators. You may use the decks generated by the app for personal or non-commercial purposes only. This means you are not allowed to use the card data or images from the game commercially unless you have explicit permission from Supercell or the original creators.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">6. Modifications and Updates</h2>
          <p className="text-white mb-4">
            We reserve the right to modify or update these terms and conditions at any time. Any changes will be posted on this page, and continued use of the app signifies acceptance of the revised terms.
          </p>

          <h2 className="text-2xl font-semibold text-white mb-2">7. Contact Information</h2>
          <p className="text-white mb-4">
            If you have any questions or concerns regarding these terms and conditions, feel free to contact us via our blog or community forums.
          </p>

          <p className="text-white mb-4">
            Thank you for using our Clash Royale Deck Builder Web App!
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
